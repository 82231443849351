var axios = require('axios');

export default class GradoService {

    constructor() {
        
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE_REPOSITORIO_GRADO;
    }

    /* ----------------------------------------------------- Documentos de Grado*/
    showDocumento(id) {
        return axios.get('api/Documento/show?id=' + id);
    }

    indexDocumento() {
        return axios.defaults.baseURL + 'api/Documento/index';
    }

    listDocumento() {
        return axios.get('api/Documento/list');
    }

     /* ----------------------------------------------------- Carrera*/
     listCarrera() {
        return axios.get('api/Carrera/list');
    }

    /* ----------------------------------------------------- Repartición*/
    listReparticion() {
        return axios.get('api/Reparticion/list');
    }

     /* ----------------------------------------------------- TipoDocumento*/
     listTipoDocumento() {
        return axios.get('api/TipoDocumento/list');
    }


}