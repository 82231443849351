import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReportePage",
    data() {
        let ss = new MainService();

        return {
            msg: "ReportePage",
            ss: ss,

            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            
            reservas: [],
            reserva: {},

            personas: [],
            persona: {},
            queryPersona: "",

            reparticiones: [],
            reparticion: {},

            especialidads: [],
            especialidad:{},

            unidadacademicas: [],
            unidadacademica: {},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
    },

    getEspecialidad() {
        this.ss.listEspecialidad().then(
            (result) => {
                let response = result.data;
                this.especialidads = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    getUnidadAcademica() {
        this.ss.listUnidadAcademica().then(
            (result) => {
                let response = result.data;
                this.unidadacademicas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    //Reporte General
    reporteGeneralPDFRango(){
        let reparticion = this.reserva.Reparticion;
        let fechaIni = this.reserva.FechaPrestamo1;
        let fechaFin = this.reserva.FechaPrestamo2;

        let objeto = {
            Reparticion: reparticion,
            FechaPrestamo1 : fechaIni,
            FechaPrestamo2 : fechaFin
        };

        this.ss.reporteGeneralPDFRango(objeto).then(
            (result) => {
                let response = result.data;
                var dir =this.rutaApp + response.url;
                window.open(dir);
                console.log(response);
            }
        ).catch(error => {
            console.log(error);
            this.isLoading = false;
        });
    },

    //Reporte por Estudiante
    reporteEstudiante(){
        let nroCI = this.persona.nroCI;
        
        let objeto = {
            nroCI: nroCI
        };

        this.ss.reporteEstudiante(objeto).then(
            (result) => {
                let response = result.data;
                var dir =this.rutaApp + response.url;
                window.open(dir);
                console.log(response);
            }
        ).catch(error => {
            console.log(error);
            this.isLoading = false;
        });
    },

    //Reporte por Carrera
    reporteCarrera(){
        let UnidadAcademica = this.unidadacademica.UnidadAcademica;
        let Esp = this.especialidad.Especialidad;
        let FechaI = this.reserva.FechaPrestamo1;
        let FechaF = this.reserva.FechaPrestamo2;
        
        let objeto = {
            UnidadAcademica: UnidadAcademica,
            Esp: Esp,
            FechaI: FechaI,
            FechaF: FechaF
        };

        console.log(objeto);

        this.ss.reporteCarrera(objeto).then(
            (result) => {
                let response = result.data;
                var dir =this.rutaApp + response.url;
                window.open(dir);
                console.log(response);
            }
        ).catch(error => {
            console.log(error);
            this.isLoading = false;
        });
    },

    excelReporteGeneral(){
        let url = "api/Reserva/get_excel?ReporteGeneral="+this.ingresopresupuestario.id;
        console.log(this.rutaApp+url);
        window.open(this.rutaApp+url);
        // this.ss.excellngresoPresupuestarioDetalle({"IngresoPresupuestario": this.ingresopresupuestario.id})
        //     .then((result) => {
        //         // let response = result.data;
        //         console.log(result);
        //         this.$bvToast.toast(
        //             "Se descargo exitosamente el archivo",
        //             {
        //             title: 'Correcto',
        //             variant: 'success',
        //             autoHideDelay: 5000
        //             }
        //         )
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
    },


    },
    watch: {
        queryPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log('hola',res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getReparticion();
        this.getEspecialidad();
        this.getUnidadAcademica();
    }
};
