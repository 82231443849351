import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

import Raphael from 'raphael/raphael'
global.Raphael = Raphael

import { BarChart } from 'vue-morris'
import { DonutChart } from 'vue-morris'

export default {
    name: "EstadisticasPage",
    data() {
        let ss = new MainService();
        return {
            msg: "EstadisticasPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },

            filtro:{},

            array: [],

            unidadacademicas: [],
            unidadacademica: {},

            nivelacademicos:[],
            nivelacademico:{},

            especialidads: [],
            especialidad:{},

            personas: [],
            persona:{},

            reservas: [],
            reserva:{},

            donutData: [
                { label: 'Red', value: 300 },
                { label: 'Blue', value: 50 },
                { label: 'Yellow', value: 100 }
            ]

        };
    },
    methods: {

    getUnidadAcademica() {
        this.ss.listUnidadAcademica().then(
            (result) => {
                let response = result.data;
                this.unidadacademicas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    getNivelAcademico() {
           
        this.ss.listNivelAcademico().then(
            (result) => {
                let response = result.data;
                this.nivelacademicos = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    getEspecialidad() {

       let params = {};
       params.NivelAcademico = this.nivelacademico.NivelAcademico;

        this.ss.listEspecialidad(params).then(
            (result) => {
                let response = result.data;
                this.especialidads = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    getEstadisticas(){
        this.ss.estadisticas(this.filtro).then(
            (result) => {
                
                let array = [];
                let response = result.data.data;

                console.log(response);

                response.forEach(
                    element => array.push(element)       
                );
                
                this.array = array;

                console.log(array);
            }
        )
    },

    limpiar(){
        this.filtro = [];
        this.array = [];
    }

    },
    components: {
        dataTable,
        Loader,
        BarChart,
        DonutChart
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getUnidadAcademica();
        this.getEspecialidad();
        this.getNivelAcademico();
    }
};
