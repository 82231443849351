import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "BibliotecaVirtualPage",
    data() {
        let ss = new MainService();
        return {
            msg: "BibliotecaVirtualPage",

            persona: {},
            
            ss: ss,
            ajax: {
                "url": ss.indexBibliotecaVirtual(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'BibliotecaVirtual', name: 'BibliotecaVirtual', title: 'Biblioteca Virtual'},
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción'},
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica'},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
 
            bibliotecasvirtuales: [],
            bibliotecavirtual: {},

            unidadacademicas: [],
            unidadacademica: {},

            isLoading: false,
            isLoadingFile:false,
            errorBag: {}
        };
    },
    methods: {
        newBibliotecaVirtual() {
            this.bibliotecavirtual = {};
            this.$refs['frm-bibliotecavirtual'].show();
        },
        showBibliotecaVirtual(id) {
            this.isLoading=true;
            this.ss.showBibliotecaVirtual(id).then(
                (result) => {
                    let response = result.data;
                    this.bibliotecavirtual = response.data;
                    this.$refs['view-bibliotecavirtual'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editBibliotecaVirtual() {
            this.$refs['frm-bibliotecavirtual'].show();
            this.$refs['view-bibliotecavirtual'].hide();
        },
        cancelBibliotecaVirtual() {
            if (this.bibliotecavirtual.id) {
                this.$refs['view-bibliotecavirtual'].show();
            }
            this.$refs['frm-bibliotecavirtual'].hide();
        },
        saveBibliotecaVirtual() {
            this.ss.storeBibliotecaVirtual(this.bibliotecavirtual).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-bibliotecavirtual'].hide();
                    this.$refs['datatable-bibliotecavirtual'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteBibliotecaVirtual() {
            this.$swal({
                title: "Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyBibliotecaVirtual(this.bibliotecavirtual)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-bibliotecavirtual'].hide();
                                this.$refs['datatable-bibliotecavirtual'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        loadFileArchivo(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.bibliotecavirtual.Archivo = result.data.data;
                            //this.saveLibro();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-BibliotecaVirtual').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showBibliotecaVirtual(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        //var persona = JSON.parse(localStorage.getItem('persona'));
        this.persona = JSON.parse(localStorage.getItem('persona'));

        this.getUnidadAcademica();

        /* if (!persona) {
          this.$router.push('/Login');
        } */
    }
};
