import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'InscribetePage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,

			loginRequest: {},
			errorBag: {},
			programasPost:{},
			/* slide: 0,
			sliding: null, */
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,

			auth:{},
			persona:{},
			
			datosInfo:{},

			/* Datatable de Libro */ 
			/* msg: " LibroPage",
            ajax: {
                "url": ss.indexLibro(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Libro', name: 'Libro', title: 'Libro' },
                { data: 'Categoria', name: 'Categoria', title: 'Categoría' },
				{ data: 'Ano', name: 'Ano', title: 'Año' },
				{
                    data: 'estado2',
                    orderable: false,
                    title: 'Estado',
                    searchable: false
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ] */
			/* /////////////////////////////////////////////// */


		};

	},
    components: {
		dataTable,
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
	},
	methods: {

		/*draw() {
            window.$('.btn-datatable-Libro').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showLibro(data);
            });
        },*/

		/* getProgramasPorUA(id,idSaga) {
			this.MostrarFiltrosTipoPro=1;
			this.UAescogida=id;
			this.UAsagaEscogida=idSaga;
			let params = {
				id: id,
				idSaga:idSaga,
			}

			console.log(params);
            this.ss.listVistaPostgradoporUA(params).then(
                (result) => {
                    let response = result.data;
                    this.programasPost = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		} */
		
	},
	mounted() {
		this.persona = JSON.parse(localStorage.getItem("persona"));
		this.auth = JSON.parse(localStorage.getItem("persona"));


		window.scrollTo(0, 0);
		//this.getProgramasPost();
		if (!this.auth) {
            // this.$refs['info'].show();
        } 
		
		
	}
}