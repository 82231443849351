import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

//import Loader from "@/components/Loader";

//import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});

export default {
	name: 'BuscadorDiccionarioMilitarPage',

	data() {
		let ss = new MainService();
		return {
			//msg: "DiccionarioMilitarPage",
			ss: ss,

			ajax: {
                "url": ss.indexDiccionarioMilitar(),
                headers: ss.getToken(),
            },

            persona:{},
            auth:{},


			nrTerminos: 10,
            nrTerminos2:10,

			filtros:{
            term: "",
            limit: 10
            },

			diccionariosmilitares: [],
            diccionariomilitar: {},
			q: "",

		};

	},

    components: {
	// dataTable,
	MenuExterno,
	FooterExterno,
    },
	props: {
	msg: String
	},

	methods: {

		queryTermino(){
            this.filtros.limit=0;
           // this.nrTerminos = 1;
            console.log(this.filtros);
			this.ss.listDiccionarioMilitar(this.filtros).then((res) => {
                console.log(res);
                if(res.data.success){
                this.diccionariosmilitares = res.data.data;
					console.log(this.diccionariosmilitares);
                    this.diccionariosmilitares = this.diccionariosmilitares.slice(0, this.nrTerminos);

                }else{
                    this.diccionariosmilitares = [];
                }
 
              })
		},

      queryTermino2(){
            this.filtros.term = "";
			this.ss.listDiccionarioMilitar(this.filtros).then((res) => {
                console.log(res);
                if(res.data.success){
                this.diccionariosmilitares = res.data.data;
					console.log(this.diccionariosmilitares);
                    //this.diccionariosmilitares = this.diccionariosmilitares.slice(0, this.nrTerminos2);

                }else{
                    this.diccionariosmilitares = [];
                }
 
              })
		}, 

		verMasTerminos(){
            if(this.filtros.term){
                this.queryTermino();
                this.nrTerminos = this.nrTerminos + 1;
           } 
           else{
                this.queryTermino2();
                this.nrTerminos2 = this.nrTerminos2 + 10;
                this.filtros.limit=this.nrTerminos2;
           }
           /* this.nrTerminos = this.nrTerminos + 1;
           this.getDiccionarioMilitar();  */
        },
        verMenosTerminos(){
            if(this.filtros.term){
                this.queryTermino();
                this.nrTerminos = this.nrTerminos - 1;
            } 
           /* this.nrTerminos = this.nrTerminos - 1;
            this.getDiccionarioMilitar(); */
            else{
                this.queryTermino2();
                this.nrTerminos2 = this.nrTerminos2 - 10;
                this.filtros.limit=this.nrTerminos2;
           }
        }, 

		/* queryTermino(){
			this.ss.select2DiccionarioMilitar(this.q).then((res) => {
                console.log(res);

                if(res.data.success){
                this.diccionariosmilitares = res.data.items;
					console.log(this.diccionariosmilitares);
                }else{
                    this.diccionariosmilitares = [];
                }

              })
	},  */

	queryVacio(){
			this.queryTermino2();
			//this.filtros.term = "";
            this.filtros.limit=10;
			//this.q = "";
	},

	getDiccionarioMilitar() {
            this.ss.listDiccionarioMilitar().then(
                (result) => {
                    let response = result.data;
                    this.diccionariosmilitares = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
    },

    /////////////////////////////////////////////Loguearse
    verificarDatosCompletos(){
        this.auth = JSON.parse(localStorage.getItem("persona"))
        if(this.auth == null || this.auth == ""){
            // this.$swal({
            // 	title: "Usted no Inició Sesion",
            // 	text: "Por favor logueate, Luego podras inscribirte",
            // 	icon: "warning",
            // 	buttons: true,
            //     dangerMode: true,});
                this.$swal({
                    title: "No iniciaste sesión.",
                    text: "¡Por favor primero logueate para ver más detalles!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then(function() {
                    //window.location = "Login";
                    this.$router.push("Libro");
                    //console.log('Hola!');
                });
                return;
        }
    }

	},
	mounted() {
        this.persona = JSON.parse(localStorage.getItem("persona"));
		this.auth = JSON.parse(localStorage.getItem("persona"));
        
		window.scrollTo(0, 0);

		this.queryTermino2();


		/* if (!this.auth) {
            //this.$refs['info'].show();
        } else{
			this.getLibro();
		} */
	}
} 

