import { render, staticRenderFns } from "./EspecialidadPage.vue?vue&type=template&id=4d6c0bf0"
import script from "./EspecialidadPage.js?vue&type=script&lang=js&external"
export * from "./EspecialidadPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports