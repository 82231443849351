import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

// import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: "CompletarPerfilPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CompletarPerfilPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            auth:{},
            persona: {},
            unidadAcademicas: [],
            rols: [],

            nivelacademicos: [],
            nivelacademico: {},

            especialidads: [],
            especialidad:{},

            isLoading: false,
            isLoadingFile:false,
            errorBag: {},
            password: {}
        };
    },
	components: {
		// MenuExterno,
		FooterExterno
    },
    methods: {

        /* loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.persona.Foto = result.data.data;
                            this.savePersona();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        }, */
        
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        showPersona() {
             this.ss.showPersona(this.auth.id)
                 .then(result => {
                     let response = result.data;
                     this.persona = response.data;
                 })
                 .catch(error => {
                     console.log(error);
                 });
        }, 
        editPersona() {
            this.$refs['frm-persona'].show();
        },
        cambiopassword() {
            this.$refs['view-password'].show();
        },
        changePassword() {
            this.password.Persona = this.persona.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        savePersona() {
            this.ss.storePersona(this.persona)
                .then(result => {
                    let response = result.data;
                    this.persona = response.data;

                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    this.$router.push('/Profile');
					location.reload();
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops!',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    // this.errorBag = error.data.errors;
                    
                });
        },
        cancelPersona() {
            this.$refs['frm-persona'].hide();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
        },

        verificarDatos(){
            // let ci = document.getElementById('ci').value;
            // let cod = document.getElementById("cod");
            // //let nombres = document.getElementById("nombres");
            // //let apellidopaterno = document.getElementById("apellidopaterno");
            // //let unidadacademica = document.getElementById("uni"); 
            // //let btnEnviar = document.getElementById("btnGuardar");

            // if (ci!='') {
            
            // }
            if (!this.persona.nroCI) {
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo NRO. de CI",
                        icon: "warning",
                        buttons: true
                    }
                    );
            } else if(!this.persona.Extension){
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo Extensión.",
                        icon: "warning",
                        buttons: true
                    }
                    );
            }else if(!this.persona.CodAlumno){
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo Código de Estudiante.",
                        icon: "warning",
                        buttons: true
                    }
                    );
            }else if(!this.persona.Nombres){
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo Nombre.",
                        icon: "warning",
                        buttons: true
                    }
                    );
            }else if(!this.persona.ApellidoPaterno){
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo Apellido Paterno.",
                        icon: "warning",
                        buttons: true
                    }
                    );
            }else if(!this.persona.UnidadAcademica){
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo Unidad Academica.",
                        icon: "warning",
                        buttons: true
                    }
                    );
            }
            /* else if(!this.persona.Especialidad){
                this.$swal(
                    {
                        title: "CAMPO REQUERIDO",
                        text: "Complete el campo Nivel Académico y su respectiva Especialidad.",
                        icon: "warning",
                        buttons: true
                    }
                    );
            } */

            console.log("Guardar Persona");
            this.savePersona();
        },

        getNivelAcademico() {
           
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.nivelacademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEspecialidad() {

            let params = {};
            params.NivelAcademico = this.nivelacademico.NivelAcademico;

            this.ss.listEspecialidad(params).then(
                (result) => {
                    let response = result.data;
                    this.especialidads = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('persona'));
        console.log(this.auth);
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
            this.showPersona();
            this.getRol();
            this.getUnidadAcademica();
            this.getNivelAcademico();
            this.getEspecialidad();
        }
    }
}