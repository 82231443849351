import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'

import TipoLibroPage from '@/pages/TipoLibro/TipoLibroPage.vue'
import CategoriaPage from '@/pages/Categoria/CategoriaPage.vue'
import OrigenPage from '@/pages/Origen/OrigenPage.vue'
import EditorialPage from '@/pages/Editorial/EditorialPage.vue'
import AutorPage from '@/pages/Autor/AutorPage.vue'
import EstadoPage from '@/pages/Estado/EstadoPage.vue'
import LibroPage from '@/pages/Libro/LibroPage.vue'
import ReparticionPage from '@/pages/Reparticion/ReparticionPage.vue'
import ReservaPage from '@/pages/Reserva/ReservaPage.vue'
import LibroAutorPage from '@/pages/LibroAutor/LibroAutorPage.vue'

import ClasePage from '@/pages/Clase/ClasePage.vue'
import InscribetePage from '@/pages/Inscribete/InscribetePage.vue'
import BuscadorPage from '@/pages/Buscador/BuscadorPage.vue'
import PortadaLibroPage from '@/pages/PortadaLibro/PortadaLibroPage.vue'
import BibliotecaVirtualPage from '@/pages/BibliotecaVirtual/BibliotecaVirtualPage.vue'
import DiccionarioMilitarPage from '@/pages/DiccionarioMilitar/DiccionarioMilitarPage.vue'
import BuscadorBibliotecaVirtualPage from '@/pages/BuscadorBibliotecaVirtual/BuscadorBibliotecaVirtualPage.vue'
import BuscadorDiccionarioMilitarPage from '@/pages/BuscadorDiccionarioMilitar/BuscadorDiccionarioMilitarPage.vue'

import RepoGradoPage from '@/pages/RepoGrado/RepoGradoPage.vue'
import RepoPosgradoPage from '@/pages/RepoPosgrado/RepoPosgradoPage.vue'

import UsuariosPage from '@/pages/Usuarios/UsuariosPage.vue'
import EditUnidadAcademicaPage from '@/pages/EditUnidadAcademica/EditUnidadAcademicaPage.vue'
import CompletarPerfilPage from '@/pages/CompletarPerfil/CompletarPerfilPage.vue'

import ReportePage from '@/pages/Reporte/ReportePage.vue'

import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'
import EspecialidadPage from '@/pages/Especialidad/EspecialidadPage.vue'

import EstadisticasPage from '@/pages/Estadisticas/EstadisticasPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Inscribete',
      component: InscribetePage
    },
    {
      path: '/Principal',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoLibro',
      name: 'TipoLibro',
      component: TipoLibroPage
    },
    {
      path: '/Categoria',
      name: 'Categoria',
      component: CategoriaPage
    }
    ,
    {
      path: '/Origen',
      name: 'Origen',
      component: OrigenPage
    },
    {
      path: '/Editorial',
      name: 'Editorial',
      component: EditorialPage
    },
    {
      path: '/Autor',
      name: 'Autor',
      component: AutorPage
    },
    {
      path: '/Estado',
      name: 'Estado',
      component: EstadoPage
    },
    {
      path: '/Libro',
      name: 'Libro',
      component: LibroPage
    },
    {
      path: '/Reparticion',
      name: 'Reparticion',
      component: ReparticionPage
    },
    {
      path: '/Reserva',
      name: 'Reserva',
      component: ReservaPage
    },
    {
      path: '/LibroAutor',
      name: 'LibroAutor',
      component: LibroAutorPage
    },
    {
      path: '/Inscribete',
      name: 'Inscribete',
      component: InscribetePage
    },
    {
      path: '/Clase',
      name: 'Clase',
      component: ClasePage
    },
    {
      path: '/Buscador',
      name: 'Buscador',
      component: BuscadorPage
    },
    {
      path: '/PortadaLibro',
      name: 'PortadaLibro',
      component: PortadaLibroPage
    },
    {
      path: '/BibliotecaVirtual',
      name: 'BibliotecaVirtual',
      component: BibliotecaVirtualPage
    },
    {
      path: '/DiccionarioMilitar',
      name: 'DiccionarioMilitar',
      component: DiccionarioMilitarPage
    },
    {
      path: '/BuscadorBibliotecaVirtual',
      name: 'BuscadorBibliotecaVirtual',
      component: BuscadorBibliotecaVirtualPage
    },
    {
      path: '/BuscadorDiccionarioMilitar',
      name: 'BuscadorDiccionarioMilitar',
      component: BuscadorDiccionarioMilitarPage
    },
    {
      path: '/RepoGrado',
      name: 'RepoGrado',
      component: RepoGradoPage
    },
    {
      path: '/RepoPosgrado',
      name: 'RepoPosgrado',
      component: RepoPosgradoPage
    },
    {
      path: '/Usuarios',
      name: 'Usuarios',
      component: UsuariosPage
    },
    {
      path: '/EditUnidadAcademica',
      name: 'EditUnidadAcademica',
      component: EditUnidadAcademicaPage
    },
    {
      path: '/CompletarPerfil',
      name: 'CompletarPerfil',
      component: CompletarPerfilPage
    },
    {
      path: '/Reporte',
      name: 'Reporte',
      component: ReportePage
    },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    {
      path: '/Especialidad',
      name: 'Especialidad',
      component: EspecialidadPage
    },
    {
      path: '/Estadisticas',
      name: 'Estadisticas',
      component: EstadisticasPage
    }
  ]
})