import PosgradoService from "@/services/PosgradoService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-responsive");

export default {
    name: "RepoGradoPage",
    data() {
        let ss2 = new PosgradoService();
        
        return {
            msg: "RepoGradoPage",

            ss2: ss2,

            ajax: {
                "url": ss2.indexDocumento()
                
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Documento', name: 'd.Documento', title: 'Documento', searchable: true },
                { data: 'Programa', name: 'p.Programa', title: 'Programa', searchable: true },
                { data: 'ResponsableElaboracion', name: 'd.ResponsableElaboracion', title: 'Autor', searchable: true },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica', searchable: true },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            repo_posgrados: [],
            repo_posgrado: {},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        showDocumento(id) {
            this.isLoading = true;
            this.ss2.showDocumento(id).then(
                (result) => {
                    let response = result.data;
                    this.repo_posgrado = response.data;
                    this.$refs['view-repo_posgrado'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        draw() {
            window.$('.btn-datatable-Documento').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDocumento(data);
            });
        }
    },

    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {

            this.persona = persona;
        }
    }
};
