import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "UsuariosPage",
    data() {
        let ss = new MainService();
        return {
            msg: "UsuariosPage",
            ss: ss,
            ajax: {
                "url": ss.Usuarios(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'nroCI', name: 'p.nroCI', title: 'CI' },
                // { data: 'ApellidoPaterno', name: 'p.ApellidoPaterno', title: 'Apellido Paterno' },
                // { data: 'ApellidoMaterno', name: 'p.ApellidoMaterno', title: 'Apellido Materno' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'UnidadAcademica', name: 'p.UnidadAcademica', title: 'Unidad Académica'},
                { data: 'Estado', name: 'p.Estado', title: 'Estado',
                render: function(data,type ,row)
                {
                    if ( row['Estado'] == "No deudor" ) {
                        return ' <div style="color:blue;" > '+row.Estado+' </div>'
                    }else if(row['Estado'] == "Deudor"){
                        return ' <div style="color:red;" > '+row.Estado+' </div>'
                    }
                   //return ' <div style="color:green;" > '+row.Estado+' </div>'
                }},
                /* {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                }, */
               {
                    data: 'Notificaciones',
                    orderable: false,
                    title: 'Notificaciones a Deudores',
                    searchable: false
                },
            ],

            unidadacademica:{},
            unidadacademicas: [],

            rols: [],
            personas: [],
            persona: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {}
        };
    },
    methods: {

        filtroEstado(){
            let ua = this.unidadacademica.UnidadAcademica;
            let estado = document.getElementById('Estadolist').value;

            if(estado == "Todos") estado = null;
            if(ua == "Todos") ua = null;

            this.ajax.data = function (d) {
                 d.Estado = estado;
                 d.UnidadAcademica = ua;
            }
            this.$refs['datatable-Usuarios'].reloadAjax(this.ajax);
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.isLoading=false;
                    this.persona = response.data;
                    this.$refs['view-persona'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
        },
        //Enviar aviso de morosidad
        enviarMorosidad(data) {
            console.log("enviar aviso de morosidad", data);
            this.ss.morosidad({'id': data}).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$swal(
                        {
                                    title: "Correcto",
                                    text: response.msg,
                                    icon: "success",
                                    buttons: true,
                                    dangerMode: true,
                        });
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        })
                });
        },
        draw() {
            window.$('.btn-datatable-Usuarios').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                console.log(data);
                this.enviarMorosidad(data);
                //this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getRol();
        }
    }
};
